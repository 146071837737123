import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import GalleryOverlay from "../components/GalleryOverlay";

const bgImage = "/header.jpg"

export default () => {
	const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/gallery/"}}) {
        nodes {
          id
          childImageSharp {
            fluid (maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);
	const [galleryVisible, toggleGallery] = useState(0);
	const [slideIndex, updateIndex] = useState(0);
	const openGallery = e => {
		e.preventDefault();
		if (e.target.tagName !== 'IMG') return;
		updateIndex(e.target.closest('a').dataset.imageindex);
		toggleGallery(1);
	}
	return (
		<Layout>
			<SEO pageTitle="Gallery" />
			<Header pageTitle="Gallery" bgImage={bgImage} />
			<section className="intro-text">
				<div className="container">
					Intro text about gallery. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
				</div>
			</section>
			<section className="gallery grey-bg">
				<div className="container">
					<div className="row" onClick={openGallery} >
						{data.allFile.nodes.map((node, index) => (
							<div key={node.id} className="col">
								<a data-imageindex={index} href={node.childImageSharp.fluid.src}>
									<Img className="framed-image" fluid={node.childImageSharp.fluid} />
								</a>
							</div>
						))}	
					</div>
				</div>
				{ galleryVisible ? <GalleryOverlay slideIndex={slideIndex} toggleGallery={toggleGallery} /> : null}
			</section>
		</Layout>
	);
}
