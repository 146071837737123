import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default ({toggleGallery, slideIndex}) => {
	if (typeof window === "undefined") {
    return <p>Server render</p>
  }
	const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/gallery/"}}) {
        nodes {
          id
          childImageSharp {
            fluid (maxWidth: 1920) {
              ...GatsbyImageSharpFluid
						}
          }
        }
      }
    }
  `);	
	const closeGallery = e => {
		e.preventDefault();
		toggleGallery(0);
	}
	return (
		<div className="overlay">
			<button class="close" aria-label="close" onClick={closeGallery}>
				<i class="fa fa-times"></i>
			</button>
			<Splide options={{
				start: slideIndex,
				type: `loop`,
				cover: true,
				heightRatio: 0.6,
				lazyLoad: 'nearby',
				padding: `300px`,
				gap: `10px`,
				breakpoints: {
					1600: {
					padding: `200px`
					},
					1200: {
					padding: `150px`
					},
					992: {
					padding: `100px`
					},
					768: {
					padding: `50px`
					},
					576: {
					padding: `25px`,
					gap: `5px`
					}
				}
			}}>
				{data.allFile.nodes.map(node => (
					<SplideSlide key={node.id}>
						<img
							data-splide-lazy-srcset={node.childImageSharp.fluid.srcset}
							sizes={node.childImageSharp.fluid.sizes}
							data-splide-lazy={node.childImageSharp.fluid.src}
							alt=""
						/>
					</SplideSlide>
				))}	
			</Splide>
		</div>
	);
}
